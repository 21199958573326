import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Form, Input, DatePicker, TimePicker, Select, Button, Spin } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

// Styled components
const AppWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Nanum Gothic', Arial, sans-serif;
  background-color: #f0f0f0;
  line-height : 1.6;

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const StyledForm = styled(Form)`
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 0 10px;
`;

const Section = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  @media (max-width: 600px) {
    padding: 10px;
`;

const Highlight = styled.span`
  background-color: #3498db;
  color: white;
  padding: 3px 6px;
  border-radius: 3px;
  font-weight: bold;

  @media (max-width: 600px) {
    padding: 2px 4px;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;

  @media (max-width: 600px) {
    padding: 8px 16px;
    font-size: 14px;
`;

const ErrorWrapper = styled.div`
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;

@media (max-width: 600px) {
  padding: 8px;
  font-size: 14px;
`;

const StyledCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const JobCategory = styled.div`
  margin-bottom: 15px;
`;

const MosaicText = styled.span`
  background-color: #ccc;
  color: transparent;
  user-select: none;
`;

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ConfirmationCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const API_URL = window.location.hostname === 'hopesaju.com' 
  ? 'https://hopesaju.com/api/saju-analysis'
  : 'https://wjkvbreqjpbdgqhcsyja.supabase.co/functions/v1/saju-analysis';


// API function
const analyzeSaju = async (values) => {
  try {
    console.log("Sending request with values:", values);
    const formattedValues = {
      name: values.name,
      gender: values.gender,
      birthdate: values.birthdate.format('YYYY-MM-DD'),
      birthtime: values.birthtime.format('HH:mm:ss'),
      city: values.city,
      calendar_type: values.calendarType,
      service_type: values.serviceType,
    };
    console.log("Formatted values:", formattedValues);

    const response = await axios.post(API_URL, formattedValues, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    console.log("Received response:", response.data);
    return response.data;
  } catch (error) {
    console.error('Full error object:', error);
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error data:', error.response.data);
      throw new Error(JSON.stringify(error.response.data.detail) || '서버에서 오류가 발생했습니다.');
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('서버에 연결할 수 없습니다. 네트워크 연결을 확인해주세요.');
    } else {
      console.error('Error setting up request:', error.message);
      throw new Error('요청 중 오류가 발생했습니다.');
    }
  }
};

// Components
const InputForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  return (
    <StyledForm form={form} onFinish={onSubmit} layout="vertical">
      <Form.Item name="name" label="이름" rules={[{ required: true, message: '이름을 입력해주세요' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="gender" label="성별" initialValue="female" rules={[{ required: true, message: '성별을 선택해주세요' }]}>
        <Select>
          <Option value="male">남자</Option>
          <Option value="female">여자</Option>
        </Select>
      </Form.Item>
      <Form.Item name="birthdate" label="생년월일" rules={[{ required: true, message: '생년월일을 선택해주세요' }]}>
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name="birthtime" label="태어난 시간" rules={[{ required: true, message: '태어난 시간을 선택해주세요' }]}>
        <TimePicker format="HH:mm" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name="city" label="태어난 도시" initialValue="서울" rules={[{ required: true, message: '태어난 도시를 선택해주세요' }]}>
        <Select defaultValue="서울">
          <Option value="서울">서울 부근</Option>
          <Option value="부산">부산 부근</Option>
          <Option value="대구">대구 부근</Option>
          <Option value="인천">인천 부근</Option>
          <Option value="광주">광주 부근</Option>
          <Option value="대전">대전 부근</Option>
          <Option value="울산">울산 부근</Option>
        </Select>
      </Form.Item>
      <Form.Item name="calendarType" label="양력/음력" initialValue="solar" rules={[{ required: true, message: '양력/음력을 선택해주세요' }]}>
        <Select>
          <Option value="solar">양력</Option>
          <Option value="lunar">음력</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          분석하기
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

const PersonalitySection = ({ result }) => (
  <Section>
    <h2>성격 및 특성</h2>
    <p><strong>{result.name}님은</strong> {result.analysis_result.day_character}</p>
    <p><strong>{result.name.slice(1)}님은</strong> {result.analysis_result.day_pillar_character}</p>
  </Section>
);

const JobCapabilitiesSection = ({ result, isPaid }) => {
  const capabilities = result.analysis_result.job_capabilities || {};
  const dayShichen = result.saju_info.day_zhi_shichen.split('(')[0];
  const nicknames = {
    비견: '독립 투사',
    겁재: '경쟁을 즐기는 혁신가',
    식신: '안정적인 연구자',
    상관: '창의적인 설득가',
    편재: '민첩한 전략가',
    정재: '꼼꼼한 관리자',
    편관: '담대한 리더',
    정관: '보수주의자',
    편인: '통찰력 있는 철학자',
    정인: '학구적인 멘토'
  };

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const sortedShichen = [dayShichen, ...Object.keys(capabilities).filter(s => s !== dayShichen)];

  return (
    <Section>
      <h2>직업 역량</h2>
      {sortedShichen.map((shichen, index) => (
        <div key={shichen}>
          <h3>{`${alphabet[index]}. ${nicknames[shichen]}`}</h3>
          <ul>
            {(capabilities[shichen] || []).map((capability, index) => (
              <li key={index}>
                {shichen === dayShichen || isPaid ? (
                  capability
                ) : (
                  <MosaicText>유료 버전에서 확인 가능합니다.</MosaicText>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Section>
  );
};

const JobRecommendationsSection = ({ result, isPaid }) => {
  const recommendations = result.analysis_result.job_recommendations || {};
  const dayShichen = result.saju_info.day_zhi_shichen.split('(')[0];
  const nicknames = {
    비견: '독립 투사',
    겁재: '경쟁을 즐기는 혁신가',
    식신: '안정적인 연구자',
    상관: '창의적인 설득가',
    편재: '민첩한 전략가',
    정재: '꼼꼼한 관리자',
    편관: '담대한 리더',
    정관: '보수주의자',
    편인: '통찰력 있는 철학자',
    정인: '학구적인 멘토'
  };
  const sortedShichen = [dayShichen, ...Object.keys(recommendations).filter(s => s !== dayShichen)];

  return (
    <Section>
      <h2>추천 직업군</h2>
      {sortedShichen.map((shichen) => (
        <JobCategory key={shichen}>
          <h3><Highlight>{nicknames[shichen]}</Highlight></h3>
          {shichen === dayShichen || isPaid ? (
            Object.entries(recommendations[shichen] || {}).map(([category, jobs]) => (
              <div key={category}>
                <p><strong>{category}:</strong> {Array.isArray(jobs) ? jobs.join(', ') : jobs}</p>
              </div>
            ))
          ) : (
            <MosaicText>유료 버전에서 확인 가능</MosaicText>
          )}
        </JobCategory>
      ))}
    </Section>
  );
};

const UpgradeConfirmation = ({ onConfirm, onCancel }) => (
  <OverlayWrapper>
    <ConfirmationCard>
      <p>유료버전은 "후불"입니다.</p>
      <p>보신 뒤 화면 하단에 있는 계좌로 계좌 이체 해주세요</p>
      <p>유료버전을 보신 뒤 지불을 하지 않으실 수도 있겠지만, 4,900원에 본인의 소중한 양심을 잃지 않으시길 바랍니다.</p>
      <p>지금의 이 작은 선택, 그리고 삶을 대하는 태도가 나중에 결국 본인의 운을 결정하게 됩니다.</p>
      <ButtonGroup>
        <Button onClick={onCancel}>뒤로가기</Button>
        <Button type="primary" onClick={onConfirm}>유료버전 보기</Button>
      </ButtonGroup>
    </ConfirmationCard>
  </OverlayWrapper>
);

const ConsultationSection = () => (
  <StyledCard>
    <h2>유선상담 신청하기</h2>
    <h3>상담을 통해 얻을 수 있는 정보</h3>
      <ul>
        <li>새로운 일을 시작하기에 가장 좋은 시기</li>
        <li>현재 하고 있는 일의 성과가 나는 좋은 시기</li>
        <li>불리한 시기에 대비하여 불운을 비켜가는 방법</li>
        <li>자기한테 유리한 궁합, 연애 운세 흐름 및 본인한테 어울리는 진짜 이상형 확인</li>
        <li>사업, 장사 등 금전과 관련된 운세의 흐름</li>
      </ul>
    <h3>희망사주 상담 차별점</h3>
      <ul>
        <li>당근 마켓 최고수준 만족도</li>
        <li>내담자 사주의 한계를 규정짓지 않고 내담자가 현재보다 더 나은 삶을 살 수 있도록 도움을 줄 수 있는 상담을 진행</li>
        <li>현대명리학으로 옛날이 아닌 현시대에 알맞은 솔루션 제공</li>
      </ul>
    <CTAButton href="https://www.daangn.com/kr/business-profiles/%ED%9D%AC%EB%A7%9D%EC%82%AC%EC%A3%BC-43b2570df1c5496da0ffc8b611ae67f0/">
      50,000 ₩
    </CTAButton>
  </StyledCard>
);

const UpgradeToPaySection = ({ name, onUpgrade }) => (
  <StyledCard>
    <h2>유료버전 오픈하기</h2>
    <p>내용이 마음에 드셨나요? 커피 한 잔을 아끼고 가장 적중률 높은 직업역량, 추천 직업을 확인하세요. 자기한테 맞는 일을 하는 것이 본인 인생에서 가장 운을 좋게 하는 <strong>확실한 선택</strong>입니다.</p>
    <p>지혜로운 선택은 늘 <strong>{name.slice(1)}님</strong>께 복이 되어 돌아옵니다.</p>
    <CTAButton onClick={onUpgrade}>
      4,900 ₩
    </CTAButton>
  </StyledCard>
);

const AccountInfoSection = () => (
  <StyledCard>
    <h2>입금 계좌 안내</h2>
    <p>아래 계좌로 4,900원을 입금해 주세요.</p>
    <p><strong>계좌번호:</strong> 1234-5678-90123</p>
    <p><strong>예금주:</strong> 정윤희</p>
    <p><strong>은행명:</strong> 신한은행</p>
  </StyledCard>
);

const ErrorMessage = ({ message }) => {
  let errorContent;
  try {
    errorContent = JSON.parse(message);
    if (Array.isArray(errorContent)) {
      return (
        <ErrorWrapper>
          <ul>
            {errorContent.map((err, index) => (
              <li key={index}>{err.msg}</li>
            ))}
          </ul>
        </ErrorWrapper>
      );
    }
  } catch (e) {
    errorContent = message;
  }
  
  return <ErrorWrapper>{errorContent}</ErrorWrapper>;
};

const FreeVersionResult = ({ result, onUpgrade }) => (
  <>
    <h1>{result.name}님의 사주 분석 결과 (무료 버전)</h1>
    <PersonalitySection result={result} />
    <JobCapabilitiesSection result={result} isPaid={false} />
    <JobRecommendationsSection result={result} isPaid={false} />
    <UpgradeToPaySection name={result.name} onUpgrade={onUpgrade} />
    <ConsultationSection />
  </>
);

const PaidVersionResult = ({ result }) => (
  <>
    <h1>{result.name}님의 사주 분석 결과 (유료 버전)</h1>
    <PersonalitySection result={result} />
    <JobCapabilitiesSection result={result} isPaid={true} />
    <JobRecommendationsSection result={result} isPaid={true} />
    <AccountInfoSection />
    <ConsultationSection />
  </>
);

const ResultPage = ({ result, onBack, onUpgrade }) => {
  const isPaid = result.service_type === 'paid';
  
  return (
    <AppWrapper>
      <h1>{result.name}님의 사주 분석 결과 ({isPaid ? '유료' : '무료'} 버전)</h1>
      <PersonalitySection result={result} />
      <JobCapabilitiesSection result={result} isPaid={isPaid} />
      <JobRecommendationsSection result={result} isPaid={isPaid} />
      {!isPaid && <UpgradeToPaySection name={result.name} onUpgrade={onUpgrade} />}
      {isPaid && <AccountInfoSection />}
      <ConsultationSection />
      <Button onClick={onBack} style={{ marginBottom: '20px' }}>뒤로 가기</Button>
    </AppWrapper>
  );
};

const App = () => {
  const [analysisResult, setAnalysisResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showUpgradeConfirmation, setShowUpgradeConfirmation] = useState(false);


  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const result = await analyzeSaju({
        ...values,
        serviceType: 'free'
      });
      setAnalysisResult(result);
      setError(null);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };
  
  const handleUpgrade = async () => {
    if (analysisResult) {
      setLoading(true);
      try {
        const updatedResult = await analyzeSaju({
          name: analysisResult.name,
          gender: analysisResult.gender,
          birthdate: moment(analysisResult.birthdate),
          birthtime: moment(analysisResult.birthtime, 'HH:mm:ss'),
          city: analysisResult.city,
          calendarType: analysisResult.calendar_type,
          serviceType: 'paid'
        });
        setAnalysisResult(updatedResult);
      } catch (err) {
        setError(err.message);
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBack = () => {
    setAnalysisResult(null);
    setShowUpgradeConfirmation(false);
  };

  const handleUpgradeClick = () => {
    setShowUpgradeConfirmation(true);
  };

  const handleUpgradeConfirm = async () => {
    setShowUpgradeConfirmation(false);
    setLoading(true);
    try {
      const updatedResult = await analyzeSaju({
        name: analysisResult.name,
        gender: analysisResult.gender,
        birthdate: moment(analysisResult.birthdate),
        birthtime: moment(analysisResult.birthtime, 'HH:mm:ss'),
        city: analysisResult.city,
        calendarType: analysisResult.calendar_type,
        serviceType: 'paid'
      });
      setAnalysisResult(updatedResult);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpgradeCancel = () => {
    setShowUpgradeConfirmation(false);
  };
  
  return (
    <AppWrapper>
      {error && <ErrorMessage message={error} />}
      {loading ? (
        <Spin size="large" />
      ) : !analysisResult ? (
        <>
          <h1>사주 분석</h1>
          <InputForm onSubmit={handleSubmit} />
        </>
      ) : (
        <>
          <ResultPage 
            result={analysisResult} 
            onBack={handleBack} 
            onUpgrade={handleUpgradeClick}
          />
          {showUpgradeConfirmation && (
            <UpgradeConfirmation 
              onConfirm={handleUpgradeConfirm} 
              onCancel={handleUpgradeCancel} 
            />
          )}
        </>
      )}
    </AppWrapper>
  );
};

export default App;